import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';
import PageNav from '../components/layout/PageNav';


const KnorrBrandVision = ({ data={} }) => {

  const { modules, title, description=false, id } = mapPageData(data.page)

  const [modulesToRender, heroModule ] = useModules(modules, { getHeroModule: true })

  return (
  <>
  <SEO title={title} description={description}/>
  <Layout
    className="knorr-brand"
    heroModule={heroModule}
  >
      {/* Content goes here */}
      { modulesToRender.map( ({Module, id}) =>  <Module key={id} /> )}
      

      <PageNav 
        prev={{ text: "Home", linkTo: "/" }} 
        next={{   text: "Brand Equities", linkTo: "/ourbrandequities"}}
      />
  </Layout>
  </>
)}

export default KnorrBrandVision


export const KnorrBrandVisionQuery = graphql`
  query KnorrBrandVisionQuery {
  page: contentfulPage(pageId: {eq: "brand-vision"}) {
      ...PageFragment
    }
  }
`
